import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, ja_JP } from 'core-ui';
import theme from './theme';
import { Helmet } from 'react-helmet';

const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <Helmet>
        <meta
          name="robots"
          content={process.env.REACT_APP_ROBOT_CONTENT || 'noindex,nofollow'}
        />
      </Helmet>
      <ConfigProvider locale={ja_JP} theme={theme}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <Provider store={store}>
              <ModalProvider>
                <DrawerProvider>
                  <AppRouter />
                </DrawerProvider>
              </ModalProvider>
            </Provider>
          </QueryClientProvider>
        </BrowserRouter>
      </ConfigProvider>
    </>
  );
};

export default App;
