/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  FORGOT_PASSWORD = '/forgot_password',
  LOGIN = '/login',
  NOT_FOUND = '/not-found',
  RESET_PASSWORD = '/reset_password',
  SIGN_UP = '/register',
  TODO = '/todo',
  DASHBOARD = '/',
  ACCOUNT = '/accounts',
  NEW = '/new',
  EDIT = '/edit',
  ID = '/:id',
  INSURANCE_APPLICATION = '/submit-agreement'
}
