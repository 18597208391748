import {
  Layout,
  MenuProps,
  TeamOutlined,
  UnorderedListOutlined
} from 'core-ui';
import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Logo from './components/Logo';

const menuItems: MenuProps['items'] = [
  {
    key: 'customers',
    icon: <TeamOutlined />,
    label: '顧客'
  },
  {
    key: 'submit-agreement',
    icon: React.createElement(UnorderedListOutlined),
    label: '顧客'
  },
  {
    key: 'accounts',
    icon: React.createElement(UnorderedListOutlined),
    label: 'アカウント'
  }
];

const MainLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleMenuClick = (info: any) => {
    navigate(info.key);
  };
  return (
    <Layout
      headerContent={<Header />}
      logoContent={<Logo />}
      logoClassName="flex justify-center items-center"
      menuItems={menuItems}
      menuProps={{
        onClick: handleMenuClick,
        selectedKeys: [location.pathname.replace('/', '')]
      }}
      siderStyle={{
        overflowX: 'hidden'
      }}
      contentStyle={{
        overflow: 'auto'
      }}
    >
      <Outlet />
    </Layout>
  );
};

export default MainLayout;
