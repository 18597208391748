import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';

const LoginPage = import('../pages/Login');
const InsuranceApplication = import('../pages/Customer');
const AdminAccount = import('../pages/admin/Account');
const AdminCreateAccount = import('../pages/admin/Account/create');
const AdminEditAccount = import('../pages/admin/Account/edit');
const AdminAccountDetail = import('../pages/admin/Account/detail');
const Dashboard = import('../pages/Dashboard');
const TodoPage = import('../pages/Todo');
const ForgotPasswordPage = import('../pages/ForgotPassword');
const ResetPasswordPage = import('../pages/ResetPassword');
const InsuranceApplicationConfirmPage = import(
  '../pages/InsuranceApplicationConfirm'
);

export const routes: RouteI[] = [
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage)
  },
  {
    path: RoutePath.DASHBOARD,
    Component: lazy(() => Dashboard),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    Component: lazy(() => ForgotPasswordPage)
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage)
  },
  {
    path: RoutePath.TODO,
    Component: lazy(() => TodoPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ACCOUNT,
    Component: lazy(() => AdminAccount),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ACCOUNT + RoutePath.NEW,
    Component: lazy(() => AdminCreateAccount),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ACCOUNT + RoutePath.ID,
    Component: lazy(() => AdminAccountDetail),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.ACCOUNT + RoutePath.ID + RoutePath.EDIT,
    Component: lazy(() => AdminEditAccount),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.INSURANCE_APPLICATION,
    Component: lazy(() => InsuranceApplication),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.INSURANCE_APPLICATION + RoutePath.ID,
    Component: lazy(() => InsuranceApplicationConfirmPage)
  }
];
